import React, { useEffect, useState } from 'react';
import './App.css';
import { Routes, Route, useNavigate, useLocation, Navigate } from 'react-router-dom';
import Login from './Auth/Login';
import SideNav from './Components/SideNav';
import TopNav from './Components/TopNav';
import Community from './Screens/Community/Community';
import ChatBox from './Screens/Community/ChatBox';
import Survey from './Screens/Survey/Survey';
import SurveyDetails from './Screens/Survey/SurveyDetails';
import Profile from './Screens/Profile/Profile';
import Points from './Screens/Profile/Points';
import Poll from './Screens/Community/Poll';
import Mentions from './Screens/Community/Mentions';
import Notification from './Screens/Notification/Notification';
import Home from './Screens/Home/Home';
import Criteria from './Screens/Criteria/Criteria';
import Signup from './Auth/Signup';
import { AuthContext } from './Context/AuthContext';
import Kudos from './Screens/Community/Kudos';
import CreateSurvey from './Screens/Survey/CreateSurvey';
import Settings from './Screens/Settings/Settings';
import Support from './Screens/Settings/Support';
import { NavContext } from './Context/NavContext';
import { UserDetailsContext } from './Context/UserDetailsContext';
import BasicDetails from './Auth/BasicDetails';
import { SmallNavContext } from './Context/SmallNavContext';
import { SearchContext } from './Context/SearchContext';
import PostDescription from './Screens/Community/PostDescription';
import ChangePassword from './Auth/changePassword';
import Overview from './Screens/Admin/Overview';
import SuperAdmin from './Screens/Admin/SuperAdmin';
import AccountUsers from './Screens/Admin/AccountUsers';
import Broadcast from './Screens/Admin/Broadcast';
import Profanity from './Screens/Admin/Profanity';
import CreatePassword from './Auth/CreatePassword';
import ForgotPassword from './Auth/ForgotPassword';
import { ROUTES } from './Routes/pathName';
import FeedbackThankyou from './Screens/Feedback/FeedbackThankyou';
import DayEndMail from './Screens/Feedback/DayEndMail';
import MoodMarble from './Screens/Feedback/MoodMarble';
import KarmaLink from './Screens/Feedback/KarmaLink';
import RemoFeedback from './Screens/Remo/RemoFeedback';
import CampaignUsers from './Screens/Admin/CampaignUsers';
import { REMO_COUNTRIES } from './utils/constants';

function App() {
	const location = useLocation();
	const navigate = useNavigate();

	const [user, setUser] = useState(false);
	const [userDetails, setUserDetails] = useState({});

	const [navExpanded, setNavExpanded] = useState(false);
	const [smallNavExpanded, setSmallNavExpanded] = useState(false);

	const [searchInput, setSearchInput] = useState('');

	useEffect(() => {
		let token = localStorage.getItem('token');
		if (token) {
			setUser(true);
			setUserDetails(JSON.parse(localStorage.getItem('user')));
		} else {
			setUser(false);
		}
	}, [localStorage.getItem('token')]);

	useEffect(() => {
		let user = JSON.parse(localStorage.getItem('user'))?.onboarded;
		let token = localStorage.getItem('token');
		if (
			JSON.parse(localStorage.getItem('user')) &&
			user &&
			(location.pathname === ROUTES.DETAILS || location.pathname === ROUTES.LOGIN)
		) {
			navigate('/dashboard/community/home');
		} else if (token && !user) {
			navigate('/details');
		}
	}, [localStorage.getItem('user'), user]);

	return (
		<React.Fragment>
			<AuthContext.Provider value={{ user, setUser }}>
				<SearchContext.Provider value={{ searchInput, setSearchInput }}>
					<NavContext.Provider value={{ navExpanded, setNavExpanded }}>
						<SmallNavContext.Provider value={{ smallNavExpanded, setSmallNavExpanded }}>
							<UserDetailsContext.Provider value={{ userDetails, setUserDetails }}>
								{location.pathname.includes(ROUTES.DASHBOARD) && <TopNav />}
								{location.pathname.includes(ROUTES.DASHBOARD) && <SideNav />}
								<Routes>
									<Route path={ROUTES.CREATE_PASSWORD} element={<CreatePassword />} />
									<Route path={ROUTES.FEEDBACK_THANKYOU} element={<FeedbackThankyou />} />
									<Route path={ROUTES.FEEDBACK_DAYENDEMAIL} element={<DayEndMail />} />
									<Route path={ROUTES.FEEDBACK_MOODMARBLE} element={<MoodMarble />} />
									<Route path={ROUTES.FEEDBACK_KARMA} element={<KarmaLink />} />
									<Route path={ROUTES.REMO_FEEDBACK} element={<RemoFeedback />} />
									<Route path={ROUTES.REMO_FEEDBACK_COUNTRY} element={<RemoFeedback />} />
								</Routes>
								{!user ? (
									<Routes>
										<Route path="/" element={<Navigate to={ROUTES.LOGIN} replace />} />
										<Route path={ROUTES.LOGIN} element={<Login />} />
										<Route path={ROUTES.RESET_PASSWORD} element={<ChangePassword />} />
										<Route path={ROUTES.VERIFY_PASSWORD} element={<ForgotPassword />} />
										<Route path={ROUTES.SIGN_UP} element={<Signup />} />
									</Routes>
								) : (
									<Routes>
										<Route path="/" element={<Navigate to="/dashboard/community/home" replace />} />
										<Route path="/dashboard" element={<Navigate to="/dashboard/community/home" replace />} />
										<Route path={ROUTES.DETAILS} element={<BasicDetails />} />
										<Route path="/dashboard/home" element={<Home />} />
										<Route path="/dashboard/criteria" element={<Criteria />} />
										<Route path="/dashboard/community/home" element={<Community />} />
										<Route path="/dashboard/chat" element={<ChatBox />} />
										{userDetails?.roles == 'admin' && <Route path="/dashboard/admin/survey" element={<Survey />} />}
										{userDetails?.roles == 'admin' && <Route path="/dashboard/admin/survey/create" element={<CreateSurvey />} />}
										<Route path="/dashboard/admin/survey/details" element={<SurveyDetails />} />
										<Route path="/dashboard/profile" element={<Profile />} />
										<Route path="/dashboard/settings" element={<Settings />} />
										<Route path="/dashboard/support" element={<Support />} />
										<Route path="/dashboard/notifications" element={<Notification />} />
										{userDetails?.roles == 'admin' && <Route path="/dashboard/admin/overview" element={<Overview />} />}
										{userDetails?.roles == 'admin' && <Route path="/dashboard/admin/broadcast" element={<Broadcast />} />}
										{userDetails?.isSuperAdmin && <Route path="/dashboard/admin/super/accounts" element={<SuperAdmin />} />}
										{userDetails?.isSuperAdmin && (
											<Route path="/dashboard/admin/super/accounts/users" element={<AccountUsers />} />
										)}
										{userDetails?.isSuperAdmin && <Route path="/dashboard/admin/super/profanity" element={<Profanity />} />}
										{userDetails?.isSuperAdmin && (
											<Route path="/dashboard/admin/super/campaign/users" element={<CampaignUsers />} />
										)}
										<Route path="/dashboard/community/points" element={<Points />} />
										<Route path="/dashboard/community/poll" element={<Poll />} />
										<Route path="/dashboard/community/mentions" element={<Mentions />} />
										<Route path={ROUTES.KUDOS} element={<Kudos />} />
										<Route path={ROUTES.POST_DESCRIPTION} element={<PostDescription />} />
									</Routes>
								)}
							</UserDetailsContext.Provider>
						</SmallNavContext.Provider>
					</NavContext.Provider>
				</SearchContext.Provider>
			</AuthContext.Provider>
		</React.Fragment>
	);
}

export default App;
